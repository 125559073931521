// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #343a40;
}

.chart-container {
  background-color: #495057;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.text-light {
  color: #f8f9fa !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["body {\n  background-color: #343a40;\n}\n\n.chart-container {\n  background-color: #495057;\n  padding: 20px;\n  border-radius: 10px;\n  margin-bottom: 20px;\n}\n\n.text-light {\n  color: #f8f9fa !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
