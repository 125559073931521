import React, { useEffect, useState, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useParams } from 'react-router-dom';
import { Chart, registerables } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Container, Row, Col, Card, Nav, ProgressBar, DropdownButton, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { DateTime } from 'luxon';

Chart.register(...registerables);

const MONITORED_SERVERS = [
  "reseller2.global.ba",
  "keplar.global.ba",
  "pulsar.global.ba",
  "bullet.global.ba",
  "falcon.global.ba",
  "hope.global.ba",
  "neutron.global.ba",
  "polaris.global.ba",
  "proton.global.ba",
  "reseller3.global.ba",
  "sonic.global.ba"
];

const NON_MONITORED_SERVERS = [
  "reseller1.global.ba",
  "oscar.global.ba",
  "matrix.global.ba"
];

function Dashboard() {
  const { serverName } = useParams();
  const [data, setData] = useState([]);
  const [timeRange, setTimeRange] = useState('6 HOUR'); // Default time range
  const cpuUsageRef = useRef(null);

  const fetchData = useCallback(() => {
    fetch(`https://server.sarajcicarnes.top:3001/api/data?server_name=${serverName}&time_range=${timeRange}`)
      .then(response => response.json())
      .then(data => {
        setData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [serverName, timeRange]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000); // Fetch data every 30 seconds
    return () => clearInterval(interval);
  }, [fetchData]);

  const processChartData = (metric, transformFn) => {
    const labels = data.map(item => 
      DateTime.fromISO(item.timestamp).minus({ hours: 1 }).toFormat('HH:mm:ss') // Subtract one hour
    ).reverse();

    const values = data.map(item => transformFn ? transformFn(item[metric]) : item[metric]).reverse();

    return {
      labels: labels,
      datasets: [
        {
          label: metric,
          data: values,
          fill: true,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1, // Thinner line
          tension: 0.4, // Curved lines
          pointRadius: 0, // Remove the points
        },
      ],
    };
  };

  const convertMBtoGB = (mb) => (mb / 1024).toFixed(2); // Convert MB to GB

  const latestUptime = data.length > 0 ? data[0].uptime : "N/A";
  const latestBackupProgress = data.length > 0 ? data[0].backup_progress : 0;
  const latestBackupDate = data.length > 0 ? data[0].backup_date : "N/A";
  const latestCurrentAccount = data.length > 0 ? data[0].current_account : "N/A";
  const latestCpuUsage = data.length > 0 ? data[0].cpu_usage : 0;

  const createGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0, 'green');
    gradient.addColorStop(1, 'red');
    return gradient;
  };

  const cpuUsageData = {
    labels: ['CPU Usage'],
    datasets: [
      {
        label: 'CPU Usage',
        data: [latestCpuUsage],
        backgroundColor: cpuUsageRef.current ? createGradient(cpuUsageRef.current.ctx, cpuUsageRef.current.chartArea) : 'rgba(75,192,192,0.2)',
      },
    ],
  };

  const cpuUsageOptions = {
    indexAxis: 'y',
    scales: {
      x: {
        min: 0,
        max: 100,
        ticks: {
          color: 'white',
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  return (
    <Container className="my-5">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center text-light">Server Monitoring Dashboard: {serverName}</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <DropdownButton id="dropdown-basic-button" title="Select Time Range">
            <Dropdown.Item onClick={() => handleTimeRangeChange('1 HOUR')}>Last 1 Hour</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTimeRangeChange('3 HOUR')}>Last 3 Hours</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTimeRangeChange('6 HOUR')}>Last 6 Hours</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTimeRangeChange('12 HOUR')}>Last 12 Hours</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTimeRangeChange('24 HOUR')}>Last 24 Hours</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="chart-container">
            <Line data={processChartData('wa_value')} options={{ 
              plugins: { legend: { labels: { color: 'white' } } }, 
              scales: { 
                x: { 
                  type: 'category', 
                  ticks: { 
                    color: 'white', 
                    maxTicksLimit: 10 // Reduce the number of ticks displayed
                  } 
                }, 
                y: { ticks: { color: 'white' } } 
              } 
            }} />
          </div>
        </Col>
        <Col md={6}>
          <div className="chart-container">
            <Line data={processChartData('free_memory', convertMBtoGB)} options={{ 
              plugins: { legend: { labels: { color: 'white' } } }, 
              scales: { 
                x: { 
                  type: 'category', 
                  ticks: { 
                    color: 'white', 
                    maxTicksLimit: 10 // Reduce the number of ticks displayed
                  } 
                }, 
                y: { ticks: { color: 'white' } } 
              } 
            }} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="chart-container">
            <Line data={processChartData('cpu_usage')} options={{ 
              plugins: { legend: { labels: { color: 'white' } } }, 
              scales: { 
                x: { 
                  type: 'category', 
                  ticks: { 
                    color: 'white', 
                    maxTicksLimit: 10 // Reduce the number of ticks displayed
                  } 
                }, 
                y: { ticks: { color: 'white' } } 
              } 
            }} />
          </div>
        </Col>
        <Col md={6}>
          <div className="chart-container">
            <Line data={processChartData('avg_load')} options={{ 
              plugins: { legend: { labels: { color: 'white' } } }, 
              scales: { 
                x: { 
                  type: 'category', 
                  ticks: { 
                    color: 'white', 
                    maxTicksLimit: 10 // Reduce the number of ticks displayed
                  } 
                }, 
                y: { ticks: { color: 'white' } } 
              } 
            }} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="chart-container">
            <Line data={processChartData('cpu_temp')} options={{ 
              plugins: { legend: { labels: { color: 'white' } } }, 
              scales: { 
                x: { 
                  type: 'category', 
                  ticks: { 
                    color: 'white', 
                    maxTicksLimit: 10 // Reduce the number of ticks displayed
                  } 
                }, 
                y: { ticks: { color: 'white' } } 
              } 
            }} />
          </div>
        </Col>
        <Col md={6}>
          <div className="chart-container">
            <Bar ref={cpuUsageRef} data={cpuUsageData} options={cpuUsageOptions} />
          </div>
          <Card className="bg-dark text-white" style={{ position: 'absolute', top: 10, right: 10, width: '200px' }}>
            <Card.Body>
              <Card.Title>Uptime</Card.Title>
              <Card.Text>{latestUptime}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card className="bg-dark text-white">
            <Card.Body>
              <Card.Title>Backup Progress</Card.Title>
              <ProgressBar now={latestBackupProgress} label={`${latestBackupProgress}%`} />
              <div>Date of Backup: {latestBackupDate}</div>
              <div>Current Account: {latestCurrentAccount}</div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

function App() {
  return (
    <Router>
      <div className="d-flex">
        <Nav className="flex-column bg-dark p-3" style={{ height: '100vh' }}>
          {MONITORED_SERVERS.map(server => (
            <Nav.Link as={NavLink} to={`/${server}`} className="text-light" key={server}>{server}</Nav.Link>
          ))}
          <hr className="text-light" />
          <div className="text-light mb-2">Non-monitored</div>
          {NON_MONITORED_SERVERS.map(server => (
            <Nav.Link as={NavLink} to={`/${server}`} className="text-light" key={server}>{server}</Nav.Link>
          ))}
        </Nav>
        <div className="flex-grow-1">
          <Routes>
            <Route path="/:serverName" element={<Dashboard />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
